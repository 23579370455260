import React from "react";
import proudImg from "../../assets/awardposter.jpeg";

const ProudMomentPage = ({ contactUsRef }) => {

  const scrollToContactUS = (ref) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  return (
    <div className="flex gap-[50px] lg:mx-[130px] mx-[20px] md:flex-row flex-col">
      <img
        src={proudImg}
        alt=""
        className="w-[500px] h-[629px] object-cover rounded-[20px] 320px-767px:w-full 320px-767px:h-[400px] 768px-1022px:w-[300px] 768px-1022px:h-[300px]"
      />
      <div className="flex flex-col justify-center">
        <div className="flex gap-[6px] items-center">
          <p className="text-oliveGreen text-24 m-0 font-normal">
            a.k. investments
          </p>
          <div className="bg-oliveGreen w-[99px] h-[1px]"></div>
        </div>
        <p className="text-black text-[25px] sm:text-[48px] m-0 font-semibold leading-[37px] sm:leading-[57px]">
          Proud Moments That Define Us
        </p>
        <p className="text-black text-[14px] mt-[24px] font-normal leading-[21px]">
          Our journey is a testament to innovation, perseverance, and meaningful
          impact. From groundbreaking projects that push the boundaries of
          possibility to exceptional partnerships built on trust and
          collaboration, we have consistently demonstrated our ability to
          deliver outstanding results. Every milestone we’ve achieved reflects
          not only our dedication to excellence but also our unwavering
          commitment to creating value for our clients, partners, and the
          communities we serve. As we continue to grow, we remain focused on
          transforming challenges into opportunities, driving progress, and
          setting new standards of success. Our achievements are more than just
          markers of progress—they are symbols of the trust we’ve earned, the
          goals we’ve surpassed, and the lasting impact we strive to make. With
          every step forward, we reaffirm our promise to deliver innovative
          solutions, foster strong relationships, and inspire positive change.
        </p>

        <button onClick={() => scrollToContactUS(contactUsRef)} className="text-white bg-[#688540] w-[fit-content] h-[40px] mt-[40px] rounded-full py-2 px-4 text-xs font-semibold hover:bg-[#557032]">
          Free Insurance Consultancy
        </button>
      </div>
    </div>
  );
};

export { ProudMomentPage };
