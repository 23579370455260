import React from "react";
import CoverPhoto from "../../components/CoverPhoto";
import MotorPhoto from "../../assets/motor.jpg";
import { AboutMoterInsurancePage } from "./AboutMoterInsurance";
import { OurMoterInsurancePage } from "./OurMoterInsurance";
import { TestimonialPage } from "../Testimonial";
import { useOutletContext } from "react-router-dom";

function Moter() {
  const { contactUsRef } = useOutletContext();

  return (
    <div className='w-full h-full flex flex-col gap-100'>
      <CoverPhoto title="Motor Insurance" image={MotorPhoto} />
      <AboutMoterInsurancePage contactUsRef={contactUsRef} />
      <OurMoterInsurancePage/>
      <TestimonialPage/>
    </div>
  );
}

export { Moter };
