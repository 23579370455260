import React, { useState, useEffect } from "react";
import TextWithBorders from "../components/componentHeading";
import { Carousel } from "antd";
import motor2Img from "../assets/motor2Img.jpg";
import { useNavigate } from "react-router-dom";
import Slider11 from "../assets/silder11.jpeg";
import Slider12 from "../assets/silder12.jpeg";
import Slider13 from "../assets/silder13.jpeg";
import Slider24 from "../assets/silder24.jpeg";
import Slider22 from "../assets/officephoto.jpg";
import Slider23 from "../assets/Certificate/C9-2023-24.jpg";
import Slider31 from "../assets/Certificate/C7.jpg";
import Slider32 from "../assets/Certificate/C8-2023.jpg";
import Slider33 from "../assets/Certificate/C10-Feb-24.jpg";

const OurAchievementPage = () => {
  const navigate = useNavigate();

  // const reviews = [
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  // ];

  const reviews = [
    { image: Slider11 },
    { image: Slider12 },
    { image: Slider13 },
    { image: Slider24 },
    { image: Slider22 },
    { image: Slider23 },
    { image: Slider31 },
    { image: Slider32 },
    { image: Slider33 },
  ];


  const chunkReviews = (reviews, size) => {
    const chunks = [];
    for (let i = 0; i < reviews.length; i += size) {
      chunks.push(reviews.slice(i, i + size));
    }
    return chunks;
  };

  const [chunkSize, setChunkSize] = useState(3);

  useEffect(() => {
    const updateChunkSize = () => {
      const isMediumScreen = window.innerWidth >= 768;
      setChunkSize(isMediumScreen ? 3 : 1);
    };

    updateChunkSize();
    window.addEventListener("resize", updateChunkSize);

    return () => {
      window.removeEventListener("resize", updateChunkSize);
    };
  }, []);

  const reviewChunks = chunkReviews(reviews, chunkSize);

  return (
    <div className="flex flex-col gap-[40px] px-[70px] max-800px:px-[30px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Our Achievement"
        description="Celebrating Milestones of Excellence.
Proven Success in Delivering Trusted Insurance Solutions."
      />

      <Carousel
        prefixCls="review-carousel"
        autoplay
        dots={true}
        dotPosition={"bottom"}
      >
        {reviewChunks.map((chunk, index) => (
          <div key={index} onClick={() => navigate("/achievement")} className="flex cursor-pointer gap-[30px]">
            {chunk.map((review, index) => (
              <div key={index} className="w-full flex flex-col gap-[34px] bg-white">
                <div className="flex justify-center items-center">
                  <img
                    src={review.image}
                    alt="motor2"
                    className={`w-full h-[300px] object-contain rounded-[20px] bg-[#fefefe] border border-[#607d3c47]`}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export { OurAchievementPage };
