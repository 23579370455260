import React, { useState } from "react";
import { Drawer } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "../assets/logo.png";

const HeaderFile = ({ contactUsRef }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const menuItems = [
    "Home",
    "About",
    "Motor",
    "Life",
    "Health",
    "Miscellaneous",
    "Achievement",
  ];

  const handleDrawerClose = () => setDrawerVisible(false);

  const scrollToContactUS = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  }

  return (
    <div className="flex items-center justify-between p-4">
      <img onClick={() => navigate("/home")}  src={Logo} alt="Logo" className="w-[197px] cursor-pointer h-[50px]" />

      <ul className="hidden lg:flex gap-[30px] 1023px-1265px:gap-[15px]  list-none m-0 p-0">
        {menuItems.map((item, index) => (
          <li
            key={index}
            onClick={() => navigate(item.toLowerCase())}
            className={`text-sm font-medium text-gray-800 cursor-pointer hover:text-[#688540] 1023px-1265px:text-[10px] ${location?.pathname?.includes(item.toLowerCase()) &&
              "!text-[#e7c26e]"
              }`}
          >
            {item}
          </li>
        ))}
      </ul>

      <button
        onClick={() => setDrawerVisible(true)}
        className="block lg:hidden text-gray-800 text-xl"
      >
        <MenuOutlined />
      </button>

      <div className="hidden lg:flex gap-4">
        <button className="text-[#688540] bg-white border border-[#688540] rounded-full py-2 px-4 text-xs font-semibold hover:bg-gray-100 1023px-1265px:text-[10px]"
          onClick={() => scrollToContactUS(contactUsRef)}
        >
          Free Insurance Consultancy
        </button>
        <button className="text-white bg-[#688540] rounded-full py-2 px-4 text-xs font-semibold hover:bg-[#557032] 1023px-1265px:text-[10px]"
          onClick={() => scrollToContactUS(contactUsRef)}
        >
          Free Doctor Consultancy
        </button>
      </div>

      <Drawer
        title={<img src={Logo} alt="Logo" className="w-[150px]" />}
        placement="right"
        onClose={handleDrawerClose}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
      >
        <ul className="list-none p-4">
          {menuItems.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                navigate(item.toLowerCase());
                handleDrawerClose();
              }}
              className={`text-sm font-medium text-gray-800 cursor-pointer mb-4 hover:text-[#688540] ${location?.pathname?.includes(item.toLowerCase()) &&
                "!text-[#e7c26e]"
                }`}
            >
              {item}
            </li>
          ))}
        </ul>
        <div className="flex flex-col gap-4 p-4">
          <button  onClick={() => scrollToContactUS(contactUsRef)} className="text-[#688540] bg-white border border-[#688540] rounded-full py-2 text-xs font-semibold hover:bg-gray-100">
            Free Insurance Consultancy
          </button>
          <button onClick={() => scrollToContactUS(contactUsRef)} className="text-white bg-[#688540] rounded-full py-2 text-xs font-semibold hover:bg-[#557032]">
            Free Doctor Consultancy
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export { HeaderFile };
