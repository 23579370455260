import { Form, Input, message, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { ButtonComp } from "../components/ButtonComponent";
import { ApiBaseUrl } from "../utils/constant";

const FreeConsultancyFormPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    const payload = {
      ...values,
      age: Number(values?.age),
      phone_number: Number(values?.phoneNumber),
    };
    delete payload.phoneNumber;
    try {
      const response = await fetch(
        `${ApiBaseUrl}/guestUser/form-submission`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response?.ok) {
        setLoading(false);
        throw new Error("Failed to submit form");
      }

      message.success("Form submitted successfully!");
      setLoading(false);
      form.resetFields();
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Something went wrong");
    }
  };

  return (
    <Spin spinning={loading} >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="w-full md:w-[700px] !bg-[#FFFFFF] md:p-[30px] p-[20px] rounded-[20px]"
        style={{ boxShadow: "0px 4px 62.5px 0px #00000040" }}
      >
        <p className="text-[24px] smtext-[40px] text-center font-normal text-black leading-[32px] sm:leading-[48px] px-[50px]">
          We have the solution for you! Take a Free Consultancy
        </p>
        <div className="flex justify-between w-full gap-[16px] mt-[36px] md:flex-row flex-col">
          <Form.Item
            style={{
              width: "100%",
              marginBottom: "16px",
            }}
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
            ]}
          >
            <Input prefixCls="form-input" placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
              marginBottom: "16px",
            }}
            label="Your Age"
            name="age"
            rules={[
              {
                required: true,
                message: "Please enter your age",
              },
              {
                pattern: /^[0-9]+$/,
                message: "Only numbers are allowed",
              },
            ]}
          >
            <Input
              prefixCls="form-input"
              placeholder="Enter your age"
              onKeyPress={(event) => {
                if (!/^\d$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => {
                event.target.value = event.target.value.replace(/\D/g, "");
              }}
            />
          </Form.Item>
        </div>

        <Form.Item
          label="Email Address"
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email address",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input prefixCls="form-input" placeholder="Enter Email Address" />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Please enter your phone number",
            },
            {
              pattern: /^\d{10}$/,
              message: "Phone number must be 10 digits",
            },
          ]}
        >
          <Input
            prefixCls="form-input"
            placeholder="Enter Phone Number"
            onKeyPress={(event) => {
              if (!/^\d$/.test(event?.key)) {
                event?.preventDefault();
              }
            }}
            onChange={(event) => {
              event.target.value = event?.target?.value?.replace(/\D/g, "");
            }}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          style={{
            width: "100%",
            marginBottom: "30px",
          }}
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter a description",
            },
            {
              validator(_, value) {
                if (!value || value.trim().split(/\s+/).length >= 10) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Description must have at least 10 words")
                );
              },
            },
          ]}
        >
          <TextArea
            placeholder="Write a text here..."
            autoSize={{
              minRows: 5,
              maxRows: 5,
            }}
          />
        </Form.Item>

        <ButtonComp
          textcard="Submit"
          color={"white"}
          background={"#688540"}
          height="50px"
          borderRadius="10px"
          handleAction={() => console.log("click")}
        />
      </Form>
    </Spin>
  );
};

export { FreeConsultancyFormPage };
