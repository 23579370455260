import React from "react";
import vectorBg from "../assets/Vector.png";
import userImg from "../assets/userImg.png";
import { motion } from "framer-motion";

import {
  CarInsuranceIcon,
  HealthInsuranceIcon,
  LifeInsuranceIcon,
  MiscellaneousInsuranceIcon,
} from "../utils/SVG";
import { useNavigate } from "react-router-dom";
const BuyInsurancePage = () => {
  const navigate = useNavigate();

  const insuranceOptions = [
    {
      Icon: CarInsuranceIcon,
      label: "Motor",
      link: "/motor",
    },
    {
      Icon: LifeInsuranceIcon,
      label: "Life",
      link: "/life",
    },
    {
      Icon: HealthInsuranceIcon,
      label: "Health",
      link: "/health",
    },
    {
      Icon: MiscellaneousInsuranceIcon,
      label: "Miscellaneous",
      link: "/miscellaneous",
    },
  ];

  return (
    <div className="bg-custom-gradient w-full h-full rounded-[20px]  flex justify-between relative">
      {/* left */}
      <div className="flex flex-col gap-[32px] 320px-974px:pb-[0px] 320px-974px:pt-[30px] py-[99px] pl-[32px] max-460px:pl-[16px]">
        <div>
          <div className="flex gap-[6px] items-center">
            <p className="text-white text-[15px] sm:text-24 m-0 font-normal">
              a.k. investments
            </p>
            <div className="bg-white w-[99px] h-[1px]"></div>
          </div>

          <p className="text-white text-[38px] sm:text-64 m-0 font-semibold 320px-767px:w-full w-[564px] leading-[47px] sm:leading-[76.8px] mt-[11px] mb-[16px]">
            The Next-Gen Approach to Buy Insurance
          </p>
          <p className="text-white text-14 m-0 font-normal 320px-767px:w-full w-[489px] leading-[21px]">
            Experience a Smarter, Simpler Way to Buy Insurance.
            Your Next-Gen Solution for Secure and Seamless Coverage.
          </p>
        </div>

        <div className="sm:flex  grid grid-cols-2 gap-[16px]">
          {insuranceOptions.map((option, index) => (
            <motion.div
              key={index}
              className="bg-white w-[110px] h-[110px] rounded-[12px] text-black flex flex-col items-center justify-center gap-[10px] cursor-pointer hover:bg-custom-gradient hover:text-white hover:shadow-lg hover:border-2 hover:border-white transition-all duration-300"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              onClick={() => navigate(option?.link)}
            >
              <option.Icon />
              <p className="text-14 m-0 font-normal ">{option?.label}</p>
            </motion.div>
          ))}
        </div>
        <div className="min-974px:hidden">
          <img src={userImg} alt="" className="w-[300px]" />
        </div>
      </div>

      {/* right */}
      <img src={vectorBg} alt="" className="320px-1200px:w-[300px] xl:block hidden" />
      <div className="absolute right-0 bottom-0 w-[700px] 768px-1250px:w-[400px] 320px-974px:hidden">
        <img src={userImg} alt="" />
      </div>
    </div>
  );
};

export { BuyInsurancePage };
