import international1 from "../assets/International/international1.jpg";
import international2 from "../assets/International/international2.jpg";
import international3 from "../assets/International/international3.jpg";
import international4 from "../assets/International/international4.jpg";
import international5 from "../assets/International/international5.jpg";

import domestic1 from "../assets/Domestic/1.jpg";
import domestic2 from "../assets/Domestic/2.jpg";
import domestic3 from "../assets/Domestic/3.jpg";
import domestic4 from "../assets/Domestic/4.jpg";
import domesticcricketball from "../assets/Domestic/Cricketball.jpg";
import domestic2025_1 from "../assets/Domestic/TrophyJan2025.jpg";
import domestic2025_2 from "../assets/Domestic/TrophyJan25.jpg";
import domestic2024_1 from "../assets/Domestic/Tophy2024-1.jpg";
import domestic2024_2 from "../assets/Domestic/Tophy2024-2.jpg";
import domestic2024_3 from "../assets/Domestic/Tophy2024-3.jpg";
import domestic2024_4 from "../assets/Domestic/TophyFeb-2024.jpg";

import domestic2023_1 from "../assets/Domestic/Tophy2023-1.jpg";
import domestic2023_2 from "../assets/Domestic/Tophy2023-2.jpg";
import domestic2023_3 from "../assets/Domestic/Tophy2023-3.jpg";
import domestic2023_4 from "../assets/Domestic/Tophy2023-4.jpg";

import domestic2022_0 from "../assets/Domestic/TophyMarch-2022.jpg";
import domestic2022_1 from "../assets/Domestic/Trophy2022-1.jpg";
import domestic2022_2 from "../assets/Domestic/Tophy2022-2.jpg";
import domestic2022_3 from "../assets/Domestic/Tophy2022-3.jpg";
import domestic2022_4 from "../assets/Domestic/Tophy2022-4.jpg";
import domestic2022_5 from "../assets/Domestic/Tophy2022-5.jpg";
import domestic2022_6 from "../assets/Domestic/Tophy2022-6.jpg";
import domestic2022_7 from "../assets/Domestic/Tophy2022-7.jpg";
import domestic2022_8 from "../assets/Domestic/Tophy2022-8.jpg";
import domestic2022_10 from "../assets/Domestic/Tophy2022-10.jpg";
import domestic2022_11 from "../assets/Domestic/Tophy2022-11.jpg";
import domestic2022_13 from "../assets/Domestic/Tophy2022-13.jpg";
import domestic2022_14 from "../assets/Domestic/Tophy2022-14.jpg";

import domestic2021_1 from "../assets/Domestic/Trophy2021.jpg";
import domestic2021_2 from "../assets/Domestic/Trophy2021.jpg";

import certificate1 from "../assets/Certificate/C1-2022.jpg";
import certificate2 from "../assets/Certificate/C4-March-2022.jpg";
import certificate3 from "../assets/Certificate/C3-June-2022.jpg";
import certificate4 from "../assets/Certificate/C11-J-2022.jpg";
import certificate5 from "../assets/Certificate/C6-Sept-2022.jpg";
import certificate6 from "../assets/Certificate/C5-Nov-2022.jpg";
import certificate7 from "../assets/Certificate/C8-2023.jpg";
import certificate8 from "../assets/Certificate/C9-2023-24.jpg";
import certificate9 from "../assets/Certificate/C10-Feb-24.jpg";
import certificate10 from "../assets/Certificate/C2-2024.jpg";
import certificate11 from "../assets/Certificate/C7.jpg";







export const AwardGallery = [
    // International Awards
    { type: 'international', image: international1 },
    { type: 'international', image: international2 },
    { type: 'international', image: international3 },
    { type: 'international', image: international4 },
    { type: 'international', image: international5 },

    // Domestic Awards
    { type: 'domestic', image: domestic1 },
    { type: 'domestic', image: domestic2 },
    { type: 'domestic', image: domestic3 },
    { type: 'domestic', image: domestic4 },
    { type: 'domestic', image: domesticcricketball },

    // Domestic Awards 2025
    { type: 'domestic', image: domestic2025_2 },

    // Domestic Awards 2024
    { type: 'domestic', image: domestic2024_1 },
    { type: 'domestic', image: domestic2024_2 },
    { type: 'domestic', image: domestic2024_3 },
    { type: 'domestic', image: domestic2024_4 },

    // Domestic Awards 2023
    { type: 'domestic', image: domestic2023_1 },
    { type: 'domestic', image: domestic2023_2 },
    { type: 'domestic', image: domestic2023_3 },
    { type: 'domestic', image: domestic2023_4 },

    // Domestic Awards 2022
    { type: 'domestic', image: domestic2022_0 },
    { type: 'domestic', image: domestic2022_1 },
    { type: 'domestic', image: domestic2022_2 },
    { type: 'domestic', image: domestic2022_3 },
    { type: 'domestic', image: domestic2022_4 },
    { type: 'domestic', image: domestic2022_5 },
    { type: 'domestic', image: domestic2022_6 },
    { type: 'domestic', image: domestic2022_7 },
    { type: 'domestic', image: domestic2022_8 },
    { type: 'domestic', image: domestic2022_10 },
    { type: 'domestic', image: domestic2022_11 },
    { type: 'domestic', image: domestic2022_13 },
    { type: 'domestic', image: domestic2022_14 },

    // Domestic Awards 2021
    { type: 'domestic', image: domestic2021_1 },
    { type: 'domestic', image: domestic2021_2 },


    // Certificate's
    { type: 'certificate', image: certificate1 },
    { type: 'certificate', image: certificate2 },
    { type: 'certificate', image: certificate3 },
    { type: 'certificate', image: certificate4 },
    { type: 'certificate', image: certificate5 },
    { type: 'certificate', image: certificate6 },
    { type: 'certificate', image: certificate7 },
    { type: 'certificate', image: certificate8 },
    { type: 'certificate', image: certificate9 },
    { type: 'certificate', image: certificate10 },
    { type: 'certificate', image: certificate11 },
];
