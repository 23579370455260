import React, { useState } from "react";
import TextWithBorders from "../../components/componentHeading";
import ButtonGroup from "../../components/ButtonGroup";
import { Gallery } from "react-grid-gallery";
import { AwardGallery } from "../../utils/AwardGallery";

const AchivementImgsPage = () => {
  const [selectedButton, setSelectedButton] = useState("All Awards");
  const [galleryPictures, setGalleryPictures] = useState(AwardGallery);

  const handleButtonSelect = (buttonName) => {
    if (buttonName === 'All Awards') {
      setGalleryPictures(AwardGallery);
    }
    if (buttonName === 'Domestic Awards') {
      let updatedAwardGallery = AwardGallery?.filter((el) => el?.type === 'domestic');
      setGalleryPictures(updatedAwardGallery);
    }
    if (buttonName === 'International Awards') {
      let updatedAwardGallery = AwardGallery?.filter((el) => el?.type === 'international');
      setGalleryPictures(updatedAwardGallery);
    }

    if (buttonName === 'Certificate') {
      let updatedAwardGallery = AwardGallery?.filter((el) => el?.type === 'certificate');
      setGalleryPictures(updatedAwardGallery);
    }

    setSelectedButton(buttonName);
  };

  return (
    <div className="flex flex-col justify-center items-center gap-[30px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Achievement"
        description="Building a Legacy of Success. Celebrating the Key Moments That Shape Our Story."
      />

      <div className="flex flex-col justify-center">
        <div className="w-full flex justify-center">
          <ButtonGroup
            buttons={["All Awards", "Domestic Awards", "International Awards", "Certificate"]}
            onSelect={handleButtonSelect}
          />
        </div>

        <div className="w-[90%] max-w-[1280px] m-auto mt-8 grid lg:grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1" >
          {galleryPictures?.map((el) => (
            <div className="flex items-center justify-center w-full h-[450px] bg-[#cbddf1] rounded-lg p-4" >
              <img class={`h-[400px] ${el.image.includes('Trophy2022-1') || el.type.includes('certificate') ? 'object-contain' : 'object-cover'} rounded-lg`} src={el.image} alt="" />
            </div>
          ))}
        </div>

        {/* <div className="w-[90%] m-auto mt-8" >

          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005047/pexels-photo-7005047.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/2040276/pexels-photo-2040276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/6120394/pexels-photo-6120394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/6250930/pexels-photo-6250930.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005687/pexels-photo-7005687.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005693/pexels-photo-7005693.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg" alt="" />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/6827089/pexels-photo-6827089.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005686/pexels-photo-7005686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
            </div>
          </div>

        </div> */}

      </div>
    </div>
  );
};

export { AchivementImgsPage };
